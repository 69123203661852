import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3920a414"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page_container_with_silder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomSlider = _resolveComponent("CustomSlider")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CustomSlider, { items: _ctx.items }, null, 8, ["items"]),
    _createVNode(_component_router_view, { id: "router-content" })
  ]))
}